<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Mordants are compounds that combine with a dye to keep it fixed in clothing and other
        fabrics. One such material, calcium acetate, is commonly prepared through the acid-base
        reaction between calcium hydroxide and acetic acid:
      </p>

      <p class="pl-10">
        <chemical-latex content="2CH3COOH + Ca(OH)2 -> Ca(CH3COO)2 + 2H2O" />
      </p>

      <p class="mb-4">
        How many grams of calcium hydroxide would be required to completely react with a
        <number-value :value="volAcOH" unit="\text{mL}" />
        solution of
        <number-value :value="pctAcOH" unit="\text{\%}" /> (by mass) acetic acid (density =
        <latex-number :number="density" />
        <stemble-latex content="$\text{g/mL}$" />
        )?
      </p>

      <calculation-input
        v-model="inputs.amountNeeded"
        class="mb-3"
        prepend-text="$\text{Mass}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question236',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        amountNeeded: null,
      },
    };
  },
  computed: {
    volAcOH() {
      return this.taskState.getValueBySymbol('volAcOH').content;
    },
    pctAcOH() {
      return this.taskState.getValueBySymbol('pctAcOH').content;
    },
    density() {
      return (0.001 * this.pctAcOH.toFloat() + 1.006).toFixed(3);
    },
  },
};
</script>
